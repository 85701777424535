import checkoutCardCache from '../../views/checkoutCard/cacheState';

import * as AuthQueries from '../../views/auth/queries';
import * as SignupSetPhoneNumberQueries from '../../views/signupSetPhoneNumber/queries';
import * as SignupBasicQueries from '../../views/signupBasic/queries';
import * as FindPasswordQueries from '../../views/findPassword/queries';
import * as FindPasswordSuccessQueries from '../../views/findPasswordSuccess/queries';
import * as diagnosisQueries from '../../views/diagnosis/queries';
import * as DiagnosisReportQueries from '../../views/diagnosisReport/queries';
import * as SelectMealQueries from '../../views/selectMeal/queries';
import * as CheckoutQueries from '../../views/checkout/queries';
import * as CustomCheckoutQueries from '../../views/customCheckout/queries';
import * as CheckoutAddressQueries from '../../views/checkoutAddress/queries';
import * as CheckoutCardQueries from '../../views/checkoutCard/queries';
import * as CheckoutCashQueries from '../../views/checkoutCash/queries';
import * as CheckoutEasyPayQueries from '../../views/checkoutEasyPay/queries';
import * as CheckoutCouponQueries from '../../views/checkoutCoupon/queries';
import * as PointsQueries from '../../views/points/queries';
import * as SubscriptionsExtendQueries from '../../views/subscriptionsExtend/queries';
import * as SubscriptionsChangeAddressQueries from '../../views/subscriptionsChangeAddress/queries';
import * as MyPageChangePasswordQueries from '../../views/myPageChangePassword/queries';
import * as GrainIntakeQueries from '../../views/consultationQuestionGrainIntake/queries';
import * as ProteinIntakeQueries from '../../views/consultationQuestionProteinIntake/queries';
import * as ConsultationQualitativeQueries from '../../views/consultationQualitiativeQuestion/queries';
import * as GroceryIntakeQueries from '../../views/consultationQuestionGroceryIntake/queries';
import * as FruitIntakeQueries from '../../views/consultationQuestionFruitIntake/queries';
import * as DairyIntakeQueries from '../../views/consultationQuestionDairyIntake/queries';
import * as SauceNDessertIntakeQueries from '../../views/consultationQuestionSauceIntake/queries';
import * as LivingHabitsQueries from '../../views/consultationQuestionLivingHabits/queries';
import * as ConsultationPersonalDetailQueries from '../../views/consultationQuestionPersonalDetail/queries';
import * as ConsultationQueires from '../../views/consultation/queries';
import * as CartQueries from '../../views/cart/queries';
import * as StoreQueries from '../../views/eatmarket/queries';

const removeUnassignedArgs = args => {
  const newArgs = {};
  Object.keys(args).forEach(key => {
    if (args[key] || args[key] === '') {
      newArgs[key] = args[key];
    }
  });
  return newArgs;
};

export default {
  Mutation: {
    updateAuth(_, args, { cache }) {
      const { isLoggedIn } = args;

      const { auth } = cache.readQuery({ query: AuthQueries.AUTH });
      cache.writeQuery({
        query: AuthQueries.AUTH,
        data: {
          auth: {
            ...auth,
            isLoggedIn
          }
        }
      });

      return null;
    },
    updateSignupSetInfo(_, args, { cache }) {
      const { signupSetInfo } = cache.readQuery({
        query: SignupSetPhoneNumberQueries.SIGNUP_SET_INFO
      });
      cache.writeQuery({
        query: SignupSetPhoneNumberQueries.SIGNUP_SET_INFO,
        data: {
          signupSetInfo: {
            ...signupSetInfo,
            ...removeUnassignedArgs(args)
          }
        }
      });

      return null;
    },

    updateSignupRecordMore(_, args, { cache }) {
      const { signupRecordMore } = cache.readQuery({
        query: SignupBasicQueries.SIGNUP_RECORD_MORE
      });
      cache.writeQuery({
        query: SignupBasicQueries.SIGNUP_RECORD_MORE,
        data: {
          signupRecordMore: {
            ...signupRecordMore,
            ...removeUnassignedArgs(args)
          }
        }
      });

      return null;
    },

    updateFindPassword(_, args, { cache }) {
      const { findPassword } = cache.readQuery({
        query: FindPasswordQueries.FIND_PASSWORD
      });
      cache.writeQuery({
        query: FindPasswordQueries.FIND_PASSWORD,
        data: {
          findPassword: {
            ...findPassword,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateFindPasswordSuccess(_, args, { cache }) {
      const { findPasswordSuccess } = cache.readQuery({
        query: FindPasswordSuccessQueries.FIND_PASSWORD_SUCCESS
      });
      cache.writeQuery({
        query: FindPasswordSuccessQueries.FIND_PASSWORD_SUCCESS,
        data: {
          findPasswordSuccess: {
            ...findPasswordSuccess,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateDiagnosis(_, args, { cache }) {
      const { diagnosis } = cache.readQuery({
        query: diagnosisQueries.DIAGNOSIS
      });
      cache.writeQuery({
        query: diagnosisQueries.DIAGNOSIS,
        data: {
          diagnosis: {
            ...diagnosis,
            ...removeUnassignedArgs(args)
          }
        }
      });

      return null;
    },

    updateDiagnosisReport(_, args, { cache }) {
      const { diagnosisReport } = cache.readQuery({
        query: DiagnosisReportQueries.DIAGNOSIS_REPORT
      });
      cache.writeQuery({
        query: DiagnosisReportQueries.DIAGNOSIS_REPORT,
        data: {
          diagnosisReport: {
            ...diagnosisReport,
            ...removeUnassignedArgs(args)
          }
        }
      });

      return null;
    },

    updateSelectMeal(_, args, { cache }) {
      const { selectMeal } = cache.readQuery({
        query: SelectMealQueries.SELECT_MEAL
      });
      cache.writeQuery({
        query: SelectMealQueries.SELECT_MEAL,
        data: {
          selectMeal: {
            ...selectMeal,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateCheckout(_, args, { cache }) {
      const { checkout } = cache.readQuery({
        query: CheckoutQueries.CHECKOUT
      });
      cache.writeQuery({
        query: CheckoutQueries.CHECKOUT,
        data: {
          checkout: {
            ...checkout,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateCheckoutAddress(_, args, { cache }) {
      const { checkoutAddress } = cache.readQuery({
        query: CheckoutAddressQueries.CHECKOUT_ADDRESS
      });
      cache.writeQuery({
        query: CheckoutAddressQueries.CHECKOUT_ADDRESS,
        data: {
          checkoutAddress: {
            ...checkoutAddress,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateCheckoutCard(_, args, { cache }) {
      const { checkoutCard } = cache.readQuery({
        query: CheckoutCardQueries.CHECKOUT_CARD
      });

      cache.writeQuery({
        query: CheckoutCardQueries.CHECKOUT_CARD,
        data: {
          checkoutCard: {
            ...checkoutCard,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateCheckoutCoupon(_, args, { cache }) {
      const { checkoutCoupon } = cache.readQuery({
        query: CheckoutCouponQueries.CHECKOUT_COUPON
      });

      cache.writeQuery({
        query: CheckoutCouponQueries.CHECKOUT_COUPON,
        data: {
          checkoutCoupon: {
            ...checkoutCoupon,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updatePoints(_, args, { cache }) {
      const { points } = cache.readQuery({
        query: PointsQueries.POINTS
      });

      cache.writeQuery({
        query: PointsQueries.POINTS,
        data: {
          points: {
            ...points,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    resetCheckoutCard(_, args, { cache }) {
      cache.writeQuery({
        query: CheckoutCardQueries.CHECKOUT_CARD,
        data: {
          checkoutCard: {
            ...checkoutCardCache
          }
        }
      });
      return null;
    },

    updateCheckoutCash(_, args, { cache }) {
      const { checkoutCash } = cache.readQuery({
        query: CheckoutCashQueries.CHECKOUT_CASH
      });

      cache.writeQuery({
        query: CheckoutCashQueries.CHECKOUT_CASH,
        data: {
          checkoutCash: {
            ...checkoutCash,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateCheckoutEasyPay(_, args, { cache }) {
      const { checkoutEasyPay } = cache.readQuery({
        query: CheckoutEasyPayQueries.CHECKOUT_EASYPAY
      });

      cache.writeQuery({
        query: CheckoutEasyPayQueries.CHECKOUT_EASYPAY,
        data: {
          checkoutEasyPay: {
            ...checkoutEasyPay,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateSubscriptionsExtend(_, args, { cache }) {
      const { subscriptionsExtend } = cache.readQuery({
        query: SubscriptionsExtendQueries.SUBSCRIPTIONS_EXTEND
      });

      cache.writeQuery({
        query: SubscriptionsExtendQueries.SUBSCRIPTIONS_EXTEND,
        data: {
          subscriptionsExtend: {
            ...subscriptionsExtend,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateSubscriptionsChangeAddress(_, args, { cache }) {
      const { subscriptionsChangeAddress } = cache.readQuery({
        query: SubscriptionsChangeAddressQueries.SUBSCRIPTIONS_CHANGE_ADDRESS
      });

      cache.writeQuery({
        query: SubscriptionsChangeAddressQueries.SUBSCRIPTIONS_CHANGE_ADDRESS,
        data: {
          subscriptionsChangeAddress: {
            ...subscriptionsChangeAddress,
            ...removeUnassignedArgs(args)
          }
        }
      });
      return null;
    },

    updateMyPageChangePassword(_, args, { cache }) {
      const { myPageChangePassword } = cache.readQuery({
        query: MyPageChangePasswordQueries.MY_PAGE_CHANGE_PASSWORD
      });
      cache.writeQuery({
        query: MyPageChangePasswordQueries.MY_PAGE_CHANGE_PASSWORD,
        data: {
          myPageChangePassword: {
            ...myPageChangePassword,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateCustomCheckout(_, args, { cache }) {
      const { customCheckout } = cache.readQuery({
        query: CustomCheckoutQueries.CUSTOM_CHECKOUT
      });
      cache.writeQuery({
        query: CustomCheckoutQueries.CUSTOM_CHECKOUT,
        data: {
          customCheckout: {
            ...customCheckout,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },
    updateDifficultyOfDietary(_, args, { cache }) {
      const { difficultyOfDietary } = cache.readQuery({
        query: ConsultationQualitativeQueries.GET_DIFFICULTY_OF_DIETARY
      });
      cache.writeQuery({
        query: ConsultationQualitativeQueries.GET_DIFFICULTY_OF_DIETARY,
        data: {
          difficultyOfDietary: {
            ...difficultyOfDietary,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateGrainIntake(_, args, { cache }) {
      const { nutritionStatusGrain } = cache.readQuery({
        query: GrainIntakeQueries.GET_GRAIN_INTAKE
      });

      cache.writeQuery({
        query: GrainIntakeQueries.GET_GRAIN_INTAKE,
        data: {
          nutritionStatusGrain: {
            ...nutritionStatusGrain,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateProteinIntake(_, args, { cache }) {
      const { nutritionStatusProtein } = cache.readQuery({
        query: ProteinIntakeQueries.GET_PROTEIN_INTAKE
      });

      cache.writeQuery({
        query: ProteinIntakeQueries.GET_PROTEIN_INTAKE,
        data: {
          nutritionStatusProtein: {
            ...nutritionStatusProtein,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateGroceryIntake(_, args, { cache }) {
      const { nutritionStatusGrocery } = cache.readQuery({
        query: GroceryIntakeQueries.GET_GROCERY_INTAKE
      });

      cache.writeQuery({
        query: GroceryIntakeQueries.GET_GROCERY_INTAKE,
        data: {
          nutritionStatusGrocery: {
            ...nutritionStatusGrocery,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateDairyIntake(_, args, { cache }) {
      const { nutritionStatusDairy } = cache.readQuery({
        query: DairyIntakeQueries.GET_DAIRY_INTAKE
      });

      cache.writeQuery({
        query: DairyIntakeQueries.GET_DAIRY_INTAKE,
        data: {
          nutritionStatusDairy: {
            ...nutritionStatusDairy,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateFruitIntake(_, args, { cache }) {
      const { nutritionStatusFruit } = cache.readQuery({
        query: FruitIntakeQueries.GET_FRUIT_INTAKE
      });

      cache.writeQuery({
        query: FruitIntakeQueries.GET_FRUIT_INTAKE,
        data: {
          nutritionStatusFruit: {
            ...nutritionStatusFruit,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateSauceIntake(_, args, { cache }) {
      const { nutritionStatusSauce } = cache.readQuery({
        query: SauceNDessertIntakeQueries.GET_SAUCE_INTAKE
      });

      cache.writeQuery({
        query: SauceNDessertIntakeQueries.GET_SAUCE_INTAKE,
        data: {
          nutritionStatusSauce: {
            ...nutritionStatusSauce,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateDessertIntake(_, args, { cache }) {
      const { nutritionStatusDessert } = cache.readQuery({
        query: SauceNDessertIntakeQueries.GET_DESSERT_INTAKE
      });

      cache.writeQuery({
        query: SauceNDessertIntakeQueries.GET_DESSERT_INTAKE,
        data: {
          nutritionStatusDessert: {
            ...nutritionStatusDessert,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },

    updateLivingHabits(_, args, { cache }) {
      const { livingHabits } = cache.readQuery({
        query: LivingHabitsQueries.GET_LIVING_HABITS
      });

      cache.writeQuery({
        query: LivingHabitsQueries.GET_LIVING_HABITS,
        data: {
          livingHabits: {
            ...livingHabits,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },
    updateConsultationPersonalDetail(_, args, { cache }) {
      const { consultationPersonalDetail } = cache.readQuery({
        query: ConsultationPersonalDetailQueries.GET_CONSULTATION_PERSONAL_DETAIL
      });

      cache.writeQuery({
        query: ConsultationPersonalDetailQueries.GET_CONSULTATION_PERSONAL_DETAIL,
        data: {
          consultationPersonalDetail: {
            ...consultationPersonalDetail,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },
    updateConsultationInfo(_, args, { cache }) {
      const { consultationInfo } = cache.readQuery({
        query: ConsultationQueires.GET_CONSULTATION_INFO
      });

      cache.writeQuery({
        query: ConsultationQueires.GET_CONSULTATION_INFO,
        data: {
          consultationInfo: {
            ...consultationInfo,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },
    updateVariants(_, args, { cache }) {
      const { variants } = cache.readQuery({
        query: StoreQueries.VARIANTS
      });

      cache.writeQuery({
        query: StoreQueries.VARIANTS,
        data: {
          variants: {
            ...variants,
            ...removeUnassignedArgs(args)
          }
        }
      });
    },
    updateCart(_, args, { cache }) {
      const { cart } = cache.readQuery({
        query: CartQueries.CART
      });

      cache.writeQuery({
        query: CartQueries.CART,
        data: {
          cart: {
            ...cart,
            ...removeUnassignedArgs(args)
          }
        }
      });
    }
  }
};
