/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ALL_USER_ADDRESS = gql`
  query AllUserAddress {
    allUserAddress {
      id
      applicantName
      address
      addressDetail
      contact1
      deliveryMemo
      patientInfo
      isBasic
    }
  }
`;

export const USER_ADDRESS = gql`
  query UserAddress($id: Int) {
    userAddress(id: $id) {
      id
      address
      addressDetail
      applicantName
      contact1
      deliveryMemo
    }
  }
`;

export const CHECKOUT_ADDRESS = gql`
  query CheckoutAddress {
    checkoutAddress @client {
      applicantName
      address
      addressDetail
      contact1
      deliveryMemo
      patientInfo
    }
  }
`;

export const UPDATE_CHECKOUT_ADDRESS = gql`
  mutation UpdateCheckoutAddress(
    $applicantName: String
    $address: String
    $addressDetail: String
    $contact1: String
    $deliveryMemo: String
    $patientInfo: String
  ) {
    updateCheckoutAddress(
      applicantName: $applicantName
      address: $address
      addressDetail: $addressDetail
      contact1: $contact1
      deliveryMemo: $deliveryMemo
      patientInfo: $patientInfo
    ) @client
  }
`;

export const CREATE_USER_ADDRESS = gql`
  mutation CreateUserAddress(
    $applicantName: String
    $address: String
    $addressDetail: String
    $contact1: String
    $deliveryMemo: String
    $patientInfo: String
  ) {
    createUserAddress(
      input: {
        applicantName: $applicantName
        address: $address
        addressDetail: $addressDetail
        contact1: $contact1
        deliveryMemo: $deliveryMemo
        patientInfo: $patientInfo
      }
    ) {
      id
      applicantName
      address
      contact1
      deliveryMemo
      errors {
        field
        messages
      }
    }
  }
`;
