import React from 'react';

import styled from 'styled-components';
import { Skeleton } from 'antd';
import usePromise from './usePromise';

const LoadingIndicator = async () => {
  const [loading] = usePromise;
  if (loading) return <Skeleton active paragraph={{ rows: 10 }} />;
};

export default LoadingIndicator;
