/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

// remote
export const CUSTOM_ORDER = gql`
  query CustomOrder($id: ID) {
    customOrder(id: $id) {
      id
      discountedPrice
      status
      name
      payment {
        bankInfo {
          vbankName
          vbankHolder
          vbankNum
          amount
        }
      }
    }
  }
`;

export const UPDATE_CUSTOM_ORDER = gql`
  mutation UpdateCustomOrder($id: ID, $paymentInfo1: String!, $paymentInfo2: String, $paymentMethod: OrderPaymentMethodEnumCreate!, $cardInfo: CardInputType, $expectedPrice: Int) {
    updateCustomOrder(id: $id, input: { paymentInfo1: $paymentInfo1, paymentInfo2: $paymentInfo2, paymentMethod: $paymentMethod, cardInfo: $cardInfo, expectedPrice: $expectedPrice }) {
      customOrder {
        discountedPrice
        status
        name
        payment {
          bankInfo {
            vbankHolder
            vbankName
            vbankNum
            amount
          }
        }
      }
    }
  }
`;

// client
export const CUSTOM_CHECKOUT = gql`
  query CustomCheckout {
    customCheckout @client {
      paymentInfo1
    }
  }
`;

export const UPDATE_CUSTOM_CHECKOUT = gql`
  mutation UpdateCustomCheckout($paymentInfo1: String) {
    updateCustomCheckout(paymentInfo1: $paymentInfo1) @client
  }
`;

export const CUSTOM_CHECKOUT_ORDER = gql`
  query CustomCheckoutOrder($id: ID) {
    customCheckoutOrder(id: $id) {
      price
      discountedPrice
      name
    }
  }
`;
