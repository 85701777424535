function chat(key) {
  (function(win, doc) {
    const w = win;
    w.channelPluginSettings = { pluginKey: key };
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function() {})('error ');
    }
    const d = doc;
    const ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (d.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  })(window, document);
}
export default chat;
