/* eslint-disable no-use-before-define */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-unfetch';
import jwtDecode from 'jwt-decode';

import Router from 'next/router';
import * as LoginQueries from '../../views/login/queries';
import * as AuthQueries from '../../views/auth/queries';

import initialCacheState from './initialCacheState';
import resolvers from './resolvers';
import { default as typeDefs } from './initialTypeDefs';

let apolloClient = null;

const URI = process.env.NEXT_PUBLIC_BACKEND_URI;
const GRAPHQL_URI = URI + '/graphql';

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

function create(initialState, { getCookie, setCookie, destroyCookie }) {
  // https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    // console.log(graphQLErrors);
    // console.log(operation);
    // console.log(forward);
    if (graphQLErrors) {
      const expectedErrorMessage = ['Signature has expired', '로그인에 실패했습니다.'];
      if (expectedErrorMessage.includes(graphQLErrors[0].message)) {
        const refreshToken = getCookie('refreshToken');
        if (refreshToken) {
          return new Observable(observer => {
            client
              .mutate({
                mutation: LoginQueries.REFRESH_TOKEN,
                variables: {
                  refreshToken
                }
              })
              .then(({ data }) => {
                setCookie('token', data.refreshToken.token);
                setCookie('refreshToken', data.refreshToken.refreshToken);

                const { auth } = client.readQuery({
                  query: AuthQueries.AUTH
                });
                client.writeQuery({
                  query: AuthQueries.AUTH,
                  data: {
                    auth: {
                      ...auth,
                      isLoggedIn: true
                    }
                  }
                });

                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `JWT ${data.refreshToken.token}`
                  }
                });
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };
                forward(operation).subscribe(subscriber);
              })
              .catch(error => {
                destroyCookie('token');
                destroyCookie('refreshToken');
                if (Router.router) {
                  Router.push('/login').then(() => {
                    window.scrollTo(0, 0);
                  });
                }
                return null;
              });
          });
        }
        // Token 삭제 후 로그인 페이지로 이동
        destroyCookie('token');
        destroyCookie('refreshToken');
        if (Router.router) {
          Router.push('/login').then(() => {
            window.scrollTo(0, 0);
          });
        }

        return null;
      }

      return null;
    }

    return null;
  });

  const httpLink = createHttpLink({
    uri: GRAPHQL_URI
  });

  const authLink = setContext((_, { headers }) => {
    const data = { headers: { ...headers } };
    const token = getCookie('token');
    if (token) {
      const { exp } = jwtDecode(token);
      if (exp < (new Date().getTime() + 1) / 1000) {
        // token expired!
        const refreshToken = getCookie('refreshToken');
        if (refreshToken) {
          // apollo mutate를 사용하면 해당 함수가 재호출됨. 따라서 fetch를 사용하여 쿼리를 실행함
          fetch(URI, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              query: `mutation {
                refreshToken(refreshToken: "${refreshToken}") {
                  token
                  payload
                  refreshToken
                }
              }
              `
            })
          })
            .then(responseData => {
              return responseData.json();
            })
            .then(json => {
              setCookie('token', json.data.refreshToken.token);
              setCookie('refreshToken', json.data.refreshToken.refreshToken);
              data.headers.authorization = `JWT ${json.data.refreshToken.token}`;
              return data;
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              destroyCookie('token');
              destroyCookie('refreshToken');
              if (Router.router) {
                Router.push('/login').then(() => {
                  window.scrollTo(0, 0);
                });
              }
            });
        }
      } else {
        data.headers.authorization = `JWT ${token}`;
        return data;
      }
    }
    return data;
  });

  const cache = new InMemoryCache().restore(initialState || {});
  cache.writeData(initialCacheState);

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const client = new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache,
    resolvers,
    typeDefs
  });

  return client;
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}

export { GRAPHQL_URI };
