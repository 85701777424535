/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_CONSULTATION_PERSONAL_DETAIL = gql`
  query GetConsultationPersonalDetail {
    consultationPersonalDetail @client {
      kPotassiumUnit
      pUnit
      uricAcidUnit
      albuminUnit
      totalProteinUnit
      diseaseUnit
    }
  }
`;

export const UPDATE_CONSULTATION_PERSONAL_DETAIL = gql`
  mutation UpdateConsultationPersonalDetail(
    $kPotassiumUnit: String
    $pUnit: String
    $uricAcidUnit: String
    $albuminUnit: String
    $totalProteinUnit: String
    $diseaseUnit: [String]
  ) {
    updateConsultationPersonalDetail(
      kPotassiumUnit: $kPotassiumUnit
      pUnit: $pUnit
      uricAcidUnit: $uricAcidUnit
      albuminUnit: $albuminUnit
      totalProteinUnit: $totalProteinUnit
      diseaseUnit: $diseaseUnit
    ) @client
  }
`;
