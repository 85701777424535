import gql from 'graphql-tag';

export const CHECKOUT_COUPON = gql`
  query CheckoutCoupon {
    checkoutCoupon @client {
      id
      name
      code
      discountPrice
      discountPercent
      isSubscriptionOrder
    }
  }
`;

export const UPDATE_CHECKOUT_COUPON = gql`
  mutation UpdateCheckoutCoupon(
    $id: Int
    $name: String
    $code: String
    $discountPrice: String
    $discountPercent: String
    $isSubscriptionOrder: Boolean
  ) {
    updateCheckoutCoupon(
      id: $id
      name: $name
      code: $code
      discountPrice: $discountPrice
      discountPercent: $discountPercent
      isSubscriptionOrder: $isSubscriptionOrder
    ) @client
  }
`;

export const COUPON = gql`
  query Coupon($code: String!, $isSubscriptionOrder: Boolean, $productId: Int) {
    coupon(code: $code, isSubscriptionOrder: $isSubscriptionOrder, productId: $productId) {
      id
      name
      code
      discountPrice
      discountPercent
      endDate
      isSubscriptionOrder
    }
  }
`;
