/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { Dairy } from './typeDefs';

export const GET_DAIRY_INTAKE = gql`
  query GetDairyIntake {
    nutritionStatusDairy @client {
      selectedItemList
      selectedItemIndex
      selectedItemAmount
    }
  }
`;

export const UPDATE_DAIRY_INTAKE = gql`
  mutation UpdateDairyIntake(
    $selectedItemList: [Dairy]
    $selectedItemIndex: Int
    $selectedItemAmount: String
  ) {
    updateDairyIntake(
      selectedItemList: $selectedItemList
      electedItemIndex: $selectedItemIndex
      selectedItemAmount: $selectedItemAmount
    ) @client
  }
`;
