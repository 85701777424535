export const HEADER_NAVS = [
  {
    name: '맛있저염 소개',
    path: '/about'
  },
  {
    name: '맞춤식단 이란?',
    path: '/what-is-customized-diet'
  },
  {
    name: '식단 주문하기',
    path: '/diagnosis'
  }
];
// sm: '@media only screen and (max-width: 320px)', 임의 변경

//     sm: '@media (max-width: 320px)',
//     md: '@media only screen and (min-width: 768px)',
//     lg: '@media only screen and (min-width: 992px)',
//     xl: '@media only screen and (min-width: 1200px)',
//     xxl: '@media only screen and (min-width: 1600px)'

export const THEME = {
  color: {
    blueButton: '#2F6ECD'
  },
  device: {
    mi: '@media(max-width:374px)',
    sm: '@media (max-width: 1000px)',
    md: '@media only screen and (min-width: 768px)',
    lg: '@media only screen and (min-width: 992px)',
    xl: '@media only screen and (min-width: 1200px)',
    xxl: '@media only screen and (min-width: 1600px)'
  },
  fontSize: {
    mobile: {
      headlineOne: '2.8rem',
      headlineTwo: '2.2rem',
      headlineThree: null,
      bodyOne: '1.6rem',
      bodyTwo: '1.4rem',
      bodyThree: '1.4rem',
      caption: '1rem',
      button: '1.8rem'
    },
    desktop: {
      headlineOne: '4.2rem',
      headlineTwo: '3.5rem',
      headlineThree: '3rem',
      bodyOne: '2.4rem',
      bodyTwo: '1.6rem',
      bodyThree: '1.6rem',
      caption: '1.2rem',
      buttonOne: '2.4rem',
      buttonTwo: '1.6rem'
    }
  }
};
