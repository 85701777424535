import gql from 'graphql-tag';

export const GET_ME = gql`
  {
    me {
      id
      username
    }
  }
`;

// mutation
export const UPDATE_MY_PAGE_CHANGE_PASSWORD = gql`
  mutation UpdateMyPageChangePassword($currentPassword: String, $updatedPassword: String) {
    updateMyPageChangePassword(
      currentPassword: $currentPassword
      updatedPassword: $updatedPassword
    ) @client
  }
`;

// cacheState
export const MY_PAGE_CHANGE_PASSWORD = gql`
  query MyPageChangePassword {
    myPageChangePassword @client {
      currentPassword
      updatedPassword
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $currentPassword: String
    $phoneNumber: String
    $updatedPassword: String
  ) {
    updatePassword(
      currentPassword: $currentPassword
      phoneNumber: $phoneNumber
      updatedPassword: $updatedPassword
    ) {
      username
    }
  }
`;
