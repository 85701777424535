/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ALL_MEAL_CATEGORY = gql`
  query AllMealCategory {
    allMealCategory {
      id
      name
    }
  }
`;

export const ALL_DIETARY_RESTRICTION = gql`
  query AllDietaryRestriction {
    allDietaryRestriction {
      id
    }
  }
`;

export const CREATE_DISEASE_HEALTH_RECORD = gql`
  mutation CreateDiseaseHealthRecord($disease: String, $mealName: String, $inputData: JSONString, $resultData: JSONString) {
    createDiseaseHealthRecord(disease: $disease, mealName: $mealName, inputData: $inputData, resultData: $resultData) {
      recordResult {
        user {
          id
          name
        }
        disease
        mealName
        resultData
        inputData
      }
    }
  }
`;

export const DIAGNOSIS = gql`
  query Diagnosis {
    diagnosis @client {
      relationType
      gender
      bornYear
      weight
      height
      kidneyStatus
      creatinineLevel
      checkDiagnosis
    }
  }
`;

export const UPDATE_DIAGNOSIS = gql`
  mutation UpdateDiagnosis($relationType: String, $gender: String, $bornYear: Int, $weight: Int, $height: Int, $kidneyStatus: String, $creatinineLevel: Float, $checkDiagnosis: String) {
    updateDiagnosis(
      relationType: $relationType
      gender: $gender
      bornYear: $bornYear
      weight: $weight
      height: $height
      kidneyStatus: $kidneyStatus
      creatinineLevel: $creatinineLevel
      checkDiagnosis: $checkDiagnosis
    ) @client
  }
`;

export const CREATE_RECORD_INPUT = gql`
  mutation CreateRecordInput($relationType: String, $gender: String, $bornYear: Int, $weight: Int, $height: Int, $kidneyStatus: String, $creatinineLevel: String) {
    createRecordInput(relationType: $relationType, gender: $gender, bornYear: $bornYear, weight: $weight, height: $height, kidneyStatus: $kidneyStatus, creatinineLevel: $creatinineLevel) {
      recordResult {
        id
        calorie
        carbohydrate
        egfr
        kalium
        natrium
        phosphorus
        protein
        meal {
          id
          name
          price
        }
        recordInput {
          age
          bornYear
          creatinineLevel
          gender
          height
          id
          kidneyStatus
          weight
        }
      }
    }
  }
`;
