import { Grocery } from '../../views/consultationQuestionGroceryIntake/typeDefs';
import { Dairy } from '../../views/consultationQuestionDairyIntake/typeDefs';
import { Fruit } from '../../views/consultationQuestionFruitIntake/typeDefs';
import {
  DietaryPatternLineType,
  DietaryPattern,
  ConsultationType,
  ConsultationResultType,
  ConsultationResultLineType,
  DietaryPatternType,
  ConsultationScheduleType
} from '../../views/consultation/typeDefs';

export default {
  typeDefs: [
    Grocery,
    Dairy,
    Fruit,
    DietaryPatternLineType,
    DietaryPattern,
    ConsultationType,
    ConsultationResultType,
    ConsultationResultLineType,
    DietaryPatternType,
    ConsultationScheduleType
  ]
};
