/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const CHECKOUT_CASH = gql`
  query CheckoutCash {
    checkoutCash @client {
      bank
      name
    }
  }
`;

export const UPDATE_CHECKOUT_CASH = gql`
  mutation UpdateCheckoutCash($bank: String, $name: String) {
    updateCheckoutCash(bank: $bank, name: $name) @client
  }
`;
