/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_SAUCE_INTAKE = gql`
  query GetSauceIntake {
    nutritionStatusSauce @client {
      oilUnit
      sugarUnit
      artificialSugarUnit
    }
  }
`;

export const UPDATE_SAUCE_INTAKE = gql`
  mutation UpdateSauceIntake($oilUnit: String, $sugarUnit: String, $artificialSugarUnit: String) {
    updateSauceIntake(
      oilUnit: $oilUnit
      sugarUnit: $sugarUnit
      artificialSugarUnit: $artificialSugarUnit
    ) @client
  }
`;

export const GET_DESSERT_INTAKE = gql`
  query GetDessertIntake {
    nutritionStatusDessert @client {
      desserts
      dessertUnit
    }
  }
`;

export const UPDATE_DESSERT_INTAKE = gql`
  mutation UpdateDessertIntake($desserts: [String], $dessertUnit: String) {
    updateDessertIntake(desserts: $desserts, dessertUnit: $dessertUnit) @client
  }
`;
