/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SELECT_MEAL = gql`
  query SelectMeal {
    selectMeal @client {
      perDay
      perWeek
      batchTypes
      subscription
      receivedDay
      option1
      price {
        totalMealPrice
        totalOptionPrice
        totalPrice
        discountPrice
        couponDiscountPrice
        couponDiscountPercent
        finalPrice
      }
    }
  }
`;

export const UPDATE_SELECT_MEAL = gql`
  mutation UpdateSelectMeal(
    $perDay: String
    $perWeek: String
    $batchTypes: String
    $subscription: String
    $receivedDay: String
    $option1: String
    $price: SelectMealPrice
  ) {
    updateSelectMeal(
      perDay: $perDay
      perWeek: $perWeek
      batchTypes: $batchTypes
      subscription: $subscription
      receivedDay: $receivedDay
      option1: $option1
      price: $price
    ) @client
  }
`;

export const DISCOUNT_PERCENT = gql`
  query DiscountPercent {
    discountPercent {
      id
      name
      week12DiscountPercent
      week2DiscountPercent
      week4DiscountPercent
      week24DiscountPercent
      isActive
    }
  }
`;

export const ALL_MEAL_OPTIONS = gql`
  query AllMealOption {
    allMealOption {
      id
      name
      price
    }
  }
`;
