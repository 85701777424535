/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { Grocery } from './typeDefs';

export const GET_GROCERY_INTAKE = gql`
  query GetGroceryIntake {
    nutritionStatusGrocery @client {
      selectedItemList
      selectedItemIndex
      selectedItemAmount
    }
  }
`;

export const UPDATE_GROCERY_INTAKE = gql`
  mutation UpdateGroceryIntake(
    $selectedItemList: [Grocery]
    $selectedItemIndex: Int
    $selectedItemAmount: String
  ) {
    updateGroceryIntake(
      selectedItemList: $selectedItemList
      selectedItemIndex: $selectedItemIndex
      selectedItemAmount: $selectedItemAmount
    ) @client
  }
`;
