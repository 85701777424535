/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SUBSCRIPTIONS_EXTEND = gql`
  query SubscriptionsExtend {
    subscriptionsExtend @client {
      selectedPeriod
    }
  }
`;

export const UPDATE_SUBSCRIPTIONS_EXTEND = gql`
  mutation UpdateSubscriptionsExtend($selectedPeriod: String) {
    updateSubscriptionsExtend(selectedPeriod: $selectedPeriod) @client
  }
`;
