/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

// # 핸드폰번호 유효성 검사
export const CHECK_PHONENUMBER_EXISTS = gql`
  query CheckPhoneNumberExists($phoneNumber: String!, $name: String) {
    phoneNumberExists(phoneNumber: $phoneNumber, name: $name)
  }
`;

// # 인증번호 확인
export const VALIDATE_VALIDATION_KEY = gql`
  query ValidateValidationKey($id: Int!, $validationKey: String!) {
    phoneValidation(id: $id, validationKey: $validationKey) {
      id
      created
      updated
      validationKey
      expiredTime
      isUsed
      purpose
    }
  }
`;

// # 인증번호 전송
export const SEND_VALIDATION_KEY = gql`
  mutation SendValidationKey($phoneNumber: String!) {
    createPhoneValidation(input: { phoneNumber: $phoneNumber, purpose: "signup" }) {
      id
      errors {
        field
        messages
      }
    }
  }
`;

export const SIGNUP_SET_INFO = gql`
  query SignupSetInfo {
    signupSetInfo @client {
      username
      name
      password
      isGuide
      isMarketing
      naverMemberId
      validationKey
      phoneNumberValidationId
      relations
    }
  }
`;

export const UPDATE_SIGNUP_SET_INFO = gql`
  mutation UpdateSignupSetInfo(
    $username: String
    $name: String
    $password: String
    $isGuide: Boolean
    $isMarketing: Boolean
    $naverMemberId: String
    $validationKey: String
    $phoneNumberValidationId: Int
    $relations: String
  ) {
    updateSignupSetInfo(
      username: $username
      name: $name
      naverMemberId: $naverMemberId
      password: $password
      isGuide: $isGuide
      isMarketing: $isMarketing
      validationKey: $validationKey
      phoneNumberValidationId: $phoneNumberValidationId
      relations: $relations
    ) @client
  }
`;
