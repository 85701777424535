/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SUBSCRIPTIONS_CHANGE_ADDRESS = gql`
  query SubscriptionsChangeAddress {
    subscriptionsChangeAddress @client {
      applicantName
      zipNo
      address
      addressDetail
      contact1
      deliveryMemo
      isInitialized
    }
  }
`;

export const UPDATE_SUBSCRIPTIONS_CHANGE_ADDRESS = gql`
  mutation UpdateSubscriptionsChangeAddress(
    $applicantName: String
    $zipNo: String
    $address: String
    $addressDetail: String
    $contact1: String
    $deliveryMemo: String
    $isInitialized: Boolean
  ) {
    updateSubscriptionsChangeAddress(
      applicantName: $applicantName
      zipNo: $zipNo
      address: $address
      addressDetail: $addressDetail
      contact1: $contact1
      deliveryMemo: $deliveryMemo
      isInitialized: $isInitialized
    ) @client
  }
`;
