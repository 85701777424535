/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_DIFFICULTY_OF_DIETARY = gql`
  query GetDifficultyOfDietary {
    difficultyOfDietary @client {
      difficulty
    }
  }
`;

export const UPDATE_DIFFICULTY_OF_DIETARY = gql`
  mutation UpdateDifficultyOfDietary($difficulty: String) {
    updateDifficultyOfDietary(difficulty: $difficulty) @client
  }
`;
