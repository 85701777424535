// 네이버 광고 로그분석 공통 스크립트
export const addCommonScript = () => {
    if (process.env.STAGE !== "production") return;

    const newScript = document.createElement('script');
    newScript.type = "text/javascript";
    newScript.id = "commonScript";
    const inlineScript = document.createTextNode('if(!wcs_add) var wcs_add={};wcs_add["wa"]="s_49cebbe17de2";if(!_nasa) var _nasa={};if(window.wcs){wcs.inflow("microsalts.com");wcs_do(_nasa);}');
    newScript.appendChild(inlineScript);
    document.body.appendChild(newScript);
}

// 네이버 광고 로그분석 전환 스크립트
export const addConversionScript = (type, value) => {
    if (process.env.STAGE !== "production") return;

    const newScript = document.createElement("script");
    newScript.type = "text/javascript";
    const inlineScript = document.createTextNode(`var _nasa={};if(window.wcs) _nasa["cnv"]=wcs.cnv("${type}","${value}");`);
    newScript.appendChild(inlineScript);
    document.body.appendChild(newScript);

    // 이전 공통 스크립트 제거
    const oldScript = document.querySelector("#commonScript");
    const parent = oldScript.parentNode;
    parent.removeChild(oldScript);

    // 새 공통 스크립트 추가
    addCommonScript();
}