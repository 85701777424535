/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_PROTEIN_INTAKE = gql`
  query GetProteinIntake {
    nutritionStatusProtein @client {
      breakfastMenuIndex
      breakfastQuantityIndex
      breakfastUnit
      lunchMenuIndex
      lunchQuantityIndex
      lunchUnit
      dinnerMenuIndex
      dinnerQuantityIndex
      dinnerUnit
    }
  }
`;

export const UPDATE_PROTEIN_INTAKE = gql`
  mutation UpdateProteinIntake(
    $breakfastMenuIndex: Int
    $breakfastQuantityIndex: Int
    $breakfastUnit: String
    $lunchMenuIndex: Int
    $lunchQuantityIndex: Int
    $lunchUnit: String
    $dinnerMenuIndex: Int
    $dinnerQuantityIndex: Int
    $dinnerUnit: String
  ) {
    updateProteinIntake(
      breakfastMenuIndex: $breakfastMenuIndex
      breakfastQuantityIndex: $breakfastQuantityIndex
      breakfastUnit: $breakfastUnit
      lunchMenuIndex: $lunchMenuIndex
      lunchQuantityIndex: $lunchQuantityIndex
      lunchUnit: $lunchUnit
      dinnerMenuIndex: $dinnerMenuIndex
      dinnerQuantityIndex: $dinnerQuantityIndex
      dinnerUnit: $dinnerUnit
    ) @client
  }
`;
