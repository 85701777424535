import gql from 'graphql-tag';

export const FIND_PASSWORD_SUCCESS = gql`
  query FindPasswordSuccess {
    findPasswordSuccess @client {
      updatedPassword
    }
  }
`;

export const UPDATE_FIND_PASSWORD_SUCCESS = gql`
  mutation UpdateFindPasswordSuccess($updatedPassword: String) {
    updateFindPasswordSuccess(updatedPassword: $updatedPassword) @client
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $name: String
    $phoneNumberValidationId: Int
    $validationKey: String
    $phoneNumber: String
    $updatedPassword: String
  ) {
    updatePassword(
      name: $name
      phoneNumberValidationId: $phoneNumberValidationId
      validationKey: $validationKey
      phoneNumber: $phoneNumber
      updatedPassword: $updatedPassword
    ) {
      username
    }
  }
`;

export const SEND_VALIDATION_KEY = gql`
  mutation SendValidationKey($phoneNumber: String!) {
    createPhoneValidation(input: { phoneNumber: $phoneNumber, purpose: "find_password" }) {
      id
      errors {
        field
        messages
      }
    }
  }
`;
