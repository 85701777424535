import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const CHECKOUT = gql`
  query Checkout {
    checkout @client {
      paymentMethod
    }
  }
`;

export const UPDATE_CHECKOUT = gql`
  mutation UpdateCheckout($paymentMethod: String) {
    updateCheckout(paymentMethod: $paymentMethod) @client
  }
`;

export const DELETE_USER_ADDRESS = gql`
  mutation DeleteUserAddress($id: ID!) {
    deleteUserAddress(id: $id) {
      result
    }
  }
`;
export const CREATE_USER_ADDRESS = gql`
  mutation MutationUserAddress($applicantName: String, $address: String, $addressDetail: String, $contact1: String, $deliveryMemo: String, $patientInfo: String, $isBasic: Boolean) {
    mutationUserAddress(
      input: { applicantName: $applicantName, address: $address, addressDetail: $addressDetail, contact1: $contact1, deliveryMemo: $deliveryMemo, patientInfo: $patientInfo, isBasic: $isBasic }
    ) {
      errors {
        field
      }
    }
  }
`;
export const UPDATE_USER_ADDRESS = gql`
  mutation MutationUserAddress($id: Int, $applicantName: String, $address: String, $addressDetail: String, $contact1: String, $deliveryMemo: String, $patientInfo: String, $isBasic: Boolean) {
    mutationUserAddress(
      input: {
        id: $id
        applicantName: $applicantName
        address: $address
        addressDetail: $addressDetail
        contact1: $contact1
        deliveryMemo: $deliveryMemo
        patientInfo: $patientInfo
        isBasic: $isBasic
      }
    ) {
      errors {
        field
      }
    }
  }
`;
export const CREATE_RECORD_MORE = gql`
  mutation CreateRecordMore(
    $bornYear: Int
    $gender: String
    $weight: Int
    $height: Int
    $creatinineLevel: String
    $kidneyStatus: String
    $bunStatus: String
    $proteinuria: String
    $fastingPlasma: String
    $afterTwo: String
    $hemoglobin: String
    $pressure: String
    $totalCholesterol: String
    $liverStatus: String
    $recordAvoids: [RecordAvoidNutritionType]
    $recordDiseases: [RecordDiseaseType]
  ) {
    createRecordMore(
      input: {
        bornYear: $bornYear
        gender: $gender
        weight: $weight
        height: $height
        creatinineLevel: $creatinineLevel
        kidneyStatus: $kidneyStatus
        bunStatus: $bunStatus
        proteinuria: $proteinuria
        fastingPlasma: $fastingPlasma
        afterTwo: $afterTwo
        hemoglobin: $hemoglobin
        pressure: $pressure
        totalCholesterol: $totalCholesterol
        liverStatus: $liverStatus
        recordAvoids: $recordAvoids
        recordDiseases: $recordDiseases
      }
    ) {
      result
    }
  }
`;

export const RECORD_MORE = gql`
  query RecordMore {
    recordMore {
      id
      bornYear
      gender
      weight
      height
      creatinineLevel
      kidneyStatus
      bunStatus
      proteinuria
      fastingPlasma
      afterTwo
      hemoglobin
      pressure
      totalCholesterol
      liverStatus
      recordAvoids {
        avoidNutrition
      }
      recordDiseases {
        diseaseType
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $order: OrderInput!
    $expectedPrice: Int!
    $subscriptionOrder: SubscriptionOrderInput
    $orderLine: [OrderLineInput]
    $coupon: CouponInput
    $point: Int
    $guideRequested: Boolean
    $impUid: String
  ) {
    createOrder(
      input: {
        order: $order
        expectedPrice: $expectedPrice
        subscriptionOrder: $subscriptionOrder
        orderLine: $orderLine
        coupon: $coupon
        point: $point
        guideRequested: $guideRequested
        impUid: $impUid
      }
    ) {
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export const ALL_MEAL = gql`
  query AllMeal {
    allMeal {
      id
      name
      price
      code
    }
  }
`;
