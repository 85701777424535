import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
  @import url('https://fonts.googleapis.com/css?family=Mr+Dafoe');
  @import url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css');
  

  @font-face {
    font-family: 'Nanum Square';
    font-weight: 400;
    src: url(NanumSquareR.woff2) format('woff2'),
        url(NanumSquareR.woff) format('woff'),
        url(NanumSquareR.ttf) format('truetype');
  }
  @font-face {
    font-family: 'TmoneyRoundWindRegular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

 
  html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-size: 62.5%;
    font-family: 'Spoqa Han Sans', 'sans-serif','NanumSquare';
    font-display: fallback;
  }

  html.font-loaded, body.font-loaded {
    font-family: 'Nanum Square';
    font-display: fallback;
  }

  #__next {
    min-height: 100%;
  }

  a {
    text-decoration: unset;
  }

  button {
    border: none;
    background: unset;
  }

  input {
    border-radius: 0%;
    padding: 0;
  }

  input[type='password'] {
    font-family: 'Nanum Gothic', sans-serif !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  h1 {
    font-size: ${props => props.theme.fontSize.mobile.headlineOne};
  }

  h2 {
    font-size: ${props => props.theme.fontSize.mobile.headlineTwo};
  }

  h3 {
    ${props => props.theme.device.md} {
      font-size: ${props => props.theme.fontSize.desktop.headlineThree};
    }
  }
`;

export default GlobalStyle;
