import gql from 'graphql-tag';

export const VALIDATE_VALIDATION_KEY = gql`
  query ValidateValidationKey($id: Int!, $validationKey: String!) {
    phoneValidation(id: $id, validationKey: $validationKey) {
      id
      created
      updated
      validationKey
      expiredTime
      isUsed
      purpose
    }
  }
`;

export const FIND_PASSWORD = gql`
  query FindPassword {
    findPassword @client {
      username
      phoneNumber
      validationKey
      validationId
    }
  }
`;

export const UPDATE_FIND_PASSWORD = gql`
  mutation UpdateFindPassword(
    $username: String
    $phoneNumber: String
    $validationKey: String
    $validationId: String
  ) {
    updateFindPassword(
      username: $username
      phoneNumber: $phoneNumber
      validationKey: $validationKey
      validationId: $validationId
    ) @client
  }
`;

export const SEND_VALIDATION_KEY = gql`
  mutation SendValidationKey($phoneNumber: String!, $name: String) {
    createPhoneValidation(
      input: { phoneNumber: $phoneNumber, name: $name, purpose: "find_password" }
    ) {
      id
      errors {
        field
        messages
      }
    }
  }
`;
