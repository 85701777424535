import React from 'react';

import { renderToString } from 'react-dom/server';
// eslint-disable-next-line import/no-unresolved
import { getDataFromTree } from 'react-apollo';
import { getMarkupFromTree } from '@apollo/react-hooks';
import Head from 'next/head';
import nookies from 'nookies';
import initApollo from './initApollo';

function getCookie(key, ctx) {
  if (ctx === {}) {
    return Cookies.get(key);
  }
  return nookies.get(ctx)[key];
}

function setCookie(key, value, ctx) {
  if (ctx === {}) {
    return Cookies.set(key, value);
  }
  return nookies.set(ctx, key, value, {});
}

function destroyCookie(key, ctx) {
  if (ctx === {}) {
    return Cookies.remove(key);
  }
  return nookies.destroy(ctx, key);
}

export default App => {
  return class WithData extends React.Component {
    static displayName = `WithData(${App.displayName})`;

    static async getInitialProps(ctx) {
      const {
        Component,
        router,
        ctx: { req, res }
      } = ctx;

      const apollo = initApollo(
        {},
        {
          getCookie: key => getCookie(key, ctx),
          setCookie: (key, value) => setCookie(key, value, ctx),
          destroyCookie: key => destroyCookie(key, ctx)
        }
      );

      ctx.ctx.apolloClient = apollo;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      // When redirecting, the response is finished.
      // No point in continuing to render
      if (res && res.finished) {
        return {};
      }

      if (!process.browser) {
        // Run all graphql queries in the component tree
        // and extract the resulting data
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <App {...appProps} Component={Component} router={router} apolloClient={apollo} />
          );

          // await getMarkupFromTree({
          //   renderFunction: renderToString,
          //   tree: <App {...appProps} Component={Component} router={router} apolloClient={apollo} />
          // });
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error

          console.error('Error while running `getDataFromTree`', error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo's store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState
      };
    }

    constructor(props) {
      super(props);
      // eslint-disable-next-line react/prop-types
      const { apolloState } = props;
      this.apolloClient = initApollo(apolloState, {
        getCookie: key => getCookie(key, {}),
        setCookie: (key, value) => setCookie(key, value, {}),
        destroyCookie: key => destroyCookie(key, {})
      });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};
