/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_GRAIN_INTAKE = gql`
  query GetGrainIntake {
    nutritionStatusGrain @client {
      breakfastMenuIndex
      breakfastQuantityIndex
      breakfastUnit
      lunchMenuIndex
      lunchQuantityIndex
      lunchUnit
      dinnerMenuIndex
      dinnerQuantityIndex
      dinnerUnit
    }
  }
`;

export const UPDATE_GRAIN_INTAKE = gql`
  mutation UpdateGrainIntake(
    $breakfastMenuIndex: Int
    $breakfastQuantityIndex: Int
    $breakfastUnit: String
    $lunchMenuIndex: Int
    $lunchQuantityIndex: Int
    $lunchUnit: String
    $dinnerMenuIndex: Int
    $dinnerQuantityIndex: Int
    $dinnerUnit: String
  ) {
    updateGrainIntake(
      breakfastMenuIndex: $breakfastMenuIndex
      breakfastQuantityIndex: $breakfastQuantityIndex
      breakfastUnit: $breakfastUnit
      lunchMenuIndex: $lunchMenuIndex
      lunchQuantityIndex: $lunchQuantityIndex
      lunchUnit: $lunchUnit
      dinnerMenuIndex: $dinnerMenuIndex
      dinnerQuantityIndex: $dinnerQuantityIndex
      dinnerUnit: $dinnerUnit
    ) @client
  }
`;
