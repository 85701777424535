/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const CHECKOUT_EASYPAY = gql`
  query CheckoutEasyPay {
    checkoutEasyPay @client {
      easypayOption
    }
  }
`;

export const UPDATE_CHECKOUT_EASYPAY = gql`
  mutation UpdateCheckoutEasyPay($easypayOption: String) {
    updateCheckoutEasyPay(easypayOption: $easypayOption) @client
  }
`;
