/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ALL_CARD_EVENT = gql`
  query AllCardEvent($year: Int, $month: Int) {
    allCardEvent(year: $year, month: $month) {
      id
      cardCompany {
        name
        logo
      }
      content
      startDate
    }
  }
`;

export const ALL_USER_CARD = gql`
  query AllUserCard {
    allUserCard {
      id
      company
      tail
      created
    }
  }
`;

export const CHECKOUT_CARD = gql`
  query CheckoutCard {
    checkoutCard @client {
      birth
      cardNumber
      expiryMM
      expiryYY
      pwd2digit
      selectedCardId
      selectedInstallment
      activeCardId
    }
  }
`;

export const UPDATE_CHECKOUT_CARD = gql`
  mutation UpdateCheckoutCard(
    $birth: String
    $cardNumber: String
    $expiryMM: String
    $expiryYY: String
    $pwd2digit: String
    $selectedCardId: String
    $selectedInstallment: String
    $activeCardId: String
  ) {
    updateCheckoutCard(
      birth: $birth
      cardNumber: $cardNumber
      expiryMM: $expiryMM
      expiryYY: $expiryYY
      pwd2digit: $pwd2digit
      selectedCardId: $selectedCardId
      selectedInstallment: $selectedInstallment
      isNewCardModalOpen: $isNewCardModalOpen
      activeCardId: $activeCardId
    ) @client
  }
`;

export const RESET_CHECKOUT_CARD = gql`
  mutation ResetCheckoutCard {
    resetCheckoutCard @client
  }
`;

export const CREATE_USER_CARD = gql`
  mutation CreateUserCard(
    $birth: String
    $cardNumber: String
    $expiry: String
    $pwd2digit: String
  ) {
    createUserCard(birth: $birth, cardNumber: $cardNumber, expiry: $expiry, pwd2digit: $pwd2digit) {
      id
    }
  }
`;

export const DELETE_USER_CARD = gql`
  mutation DeleteUserCard($id: String) {
    deleteUserCard(id: $id) {
      result
    }
  }
`;
