/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_LIVING_HABITS = gql`
  query GetLivingHabits {
    livingHabits @client {
      saltUnit
      drinkUnit
      smokeUnit
      exerciseUnit
    }
  }
`;

export const UPDATE_LIVING_HABITS = gql`
  mutation UpdateLivingHabits(
    $saltUnit: String
    $drinkUnit: String
    $smokeUnit: String
    $exerciseUnit: String
  ) {
    updateLivingHabits(
      saltUnit: $saltUnit
      drinkUnit: $drinkUnit
      smokeUnit: $smokeUnit
      exerciseUnit: $exerciseUnit
    ) @client
  }
`;
