import gql from 'graphql-tag';

export const DietaryPattern = gql`
  type DietaryPattern {
    id: ID!
    isPartial: Boolean!
    difficulty: String!
    salty: String!
    drinking: String!
    smoke: Boolean!
    workout: String!
    kaliumLevel: Float
    pLevel: Float
    uricAcidLevel: Float
    hasDiabetes: Boolean!
    hasHyperlipemia: Boolean!
    hasGout: Boolean!
    dietaryPatternLines: [DietaryPatternLineType]
  }
`;

export const DietaryPatternLineType = gql`
  type DietaryPatternLineType {
    category: String!
    additionalInfo: String
    name: String!
    amount: String!
    unit: Float!
  }
`;

export const ConsultationType = gql`
  type ConsultationType {
    created: DateTime!
    updated: DateTime!
    id: UUID!
    applyDueDate: DateTime!
    consultationSchedule: ConsultationScheduleType
    consultant: String
    dietaryPattern: DietaryPatternType
    consultationResult: ConsultationResultType
  }
`;

export const ConsultationResultLineType = gql`
  type ConsultationResultLineType {
    created: DateTime!
    updated: DateTime!
    id: ID!
    consultationResult: ConsultationResultType!
    category: String!
    status: String!
    relatedShortDietaryPattern: String
    statusComments: String
    tip: String
  }
`;

export const ConsultationResultType = gql`
  type ConsultationResultType {
    created: DateTime!
    updated: DateTime!
    id: ID!
    calorie: Int
    natrium: Int
    protein: Int
    carbohydrate: Int
    kalium: Int
    phosphorus: Int
    totalComments: String!
    consultationSet: [ConsultationType!]!
    consultationresultlineSet: [ConsultationResultLineType!]!
    consultationResultLines: [ConsultationResultLineType]
  }
`;

export const DietaryPatternType = gql`
  type DietaryPatternType {
    id: ID!
    isPartial: Boolean!
    difficulty: String!
    salty: String!
    drinking: String!
    smoke: Boolean!
    workout: String!
    kaliumLevel: Float
    pLevel: Float
    uricAcidLevel: Float
    hasDiabetes: Boolean!
    hasHyperlipemia: Boolean!
    hasGout: Boolean!
    dietaryPatternLines: [DietaryPatternLineType]
  }
`;

export const ConsultationScheduleType = gql`
  type ConsultationScheduleType {
    created: DateTime!
    updated: DateTime!
    id: ID!
    time: DateTime!
    consultation: ConsultationType
  }
`;
