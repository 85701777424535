import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const ALL_POINTS = gql`
  query AllPoints {
    allPoints {
      created
      amount
      expires
      order {
        id
        status
        name
        isSubscriptionOrder
        subscriptionOrder {
          id
          status
          meal {
            id
            name
          }
        }
      }
      info
    }
  }
`;

export const POINTS = gql`
  query Points {
    points @client {
      point
    }
  }
`;

export const UPDATE_POINTS = gql`
  mutation UpdatePoints($point: Int) {
    updatePoints(point: $point) @client
  }
`;
