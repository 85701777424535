/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { Fruit } from './typeDefs';

export const GET_FRUIT_INTAKE = gql`
  query GetFruitIntake {
    nutritionStatusFruit @client {
      selectedItemList
      selectedItemIndex
      selectedItemAmount
    }
  }
`;

export const UPDATE_FRUIT_INTAKE = gql`
  mutation UpdateFruitIntake(
    $selectedItemList: [Fruit]
    $selectedItemIndex: Int
    $selectedItemAmount: String
  ) {
    updateFruitIntake(
      selectedItemList: $selectedItemList
      electedItemIndex: $selectedItemIndex
      selectedItemAmount: $selectedItemAmount
    ) @client
  }
`;
