import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_RECORD_MORE = gql`
  mutation CreateRecordMore(
    $username: String
    $bornYear: Int
    $gender: String
    $weight: Int
    $height: Int
    $creatinineLevel: String
    $kidneyStatus: String
    $bunStatus: String
    $proteinuria: String
    $fastingPlasma: String
    $afterTwo: String
    $hemoglobin: String
    $pressure: String
    $totalCholesterol: String
    $liverStatus: String
    $thyroidLevel: String
    $thyroidTreat: String
    $thyroidTsh: String
    $recordAvoids: [RecordAvoidNutritionType]
    $recordDiseases: [RecordDiseaseType]
  ) {
    createRecordMore(
      input: {
        username: $username
        bornYear: $bornYear
        gender: $gender
        weight: $weight
        height: $height
        creatinineLevel: $creatinineLevel
        kidneyStatus: $kidneyStatus
        bunStatus: $bunStatus
        proteinuria: $proteinuria
        fastingPlasma: $fastingPlasma
        afterTwo: $afterTwo
        hemoglobin: $hemoglobin
        pressure: $pressure
        totalCholesterol: $totalCholesterol
        liverStatus: $liverStatus
        thyroidLevel: $thyroidLevel
        thyroidTreat: $thyroidTreat
        thyroidTsh: $thyroidTsh
        recordAvoids: $recordAvoids
        recordDiseases: $recordDiseases
      }
    ) {
      result
    }
  }
`;

export const SIGNUP_RECORD_MORE = gql`
  query SignupRecordMore {
    signupRecordMore @client {
      bornYear
      gender
      height
      weight
      creatinineLevel
      kidneyStatus
      bunStatus
      proteinuria
      fastingPlasma
      afterTwo
      hemoglobin
      pressure
      totalCholesterol
      liverStatus
      thyroidLevel
      thyroidTreat
      thyroidTsh
      recordDiseases
      recordAvoids
    }
  }
`;

export const UPDATE_SIGNUP_RECORD_MORE = gql`
  mutation UpdateSignupRecordMore(
    $bornYear: Int
    $gender: String
    $height: String
    $weight: String
    $creatinineLevel: Int
    $kidneyStatus: String
    $bunStatus: Int
    $proteinuria: String
    $fastingPlasma: String
    $afterTwo: String
    $hemoglobin: String
    $pressure: String
    $totalCholesterol: String
    $liverStatus: String
    $thyroidLevel: String
    $thyroidTreat: String
    $thyroidTsh: String
    $recordDiseases: Array
    $recordAvoids: Array
  ) {
    updateSignupRecordMore(
      bornYear: $bornYear
      gender: $gender
      height: $height
      weight: $weight
      creatinineLevel: $creatinineLevel
      kidneyStatus: $kidneyStatus
      bunStatus: $bunStatus
      proteinuria: $proteinuria
      fastingPlasma: $fastingPlasma
      afterTwo: $afterTwo
      hemoglobin: $hemoglobin
      pressure: $pressure
      totalCholesterol: $totalCholesterol
      liverStatus: $liverStatus
      thyroidLevel: $thyroidLevel
      thyroidTreat: $thyroidTreat
      thyroidTsh: $thyroidTsh
      recordDiseases: $recordDiseases
      recordAvoids: $recordAvoids
    ) @client
  }
`;
