import gql from 'graphql-tag';

export const GET_CONSULTATION_INFO = gql`
  query GetConsultationInfo {
    consultationInfo @client {
      consultationId
      dietaryPatternId
      dietaryPatternLines
      userType
      consultationType
      schedule
      applyDueDate
      recordInputId
      scheduleId
    }
  }
`;

export const CONSULTATION = gql`
  query Consultation($id: String!) {
    consultation(id: $id) {
      id
      applyDueDate
      dietaryPattern {
        id
        isPartial
      }
      consultationSchedule {
        id
        time
      }
      consultationType
    }
  }
`;
export const GET_CONSULTATION_REPORT_DATA = gql`
  query GetConsultationReportData($id: String!) {
    consultation(id: $id) {
      id
      created
      consultationType
      consultationResult {
        calorie
        natrium
        protein
        carbohydrate
        phosphorus
        kalium
        totalComments
        consultationResultLines {
          status
          category
          relatedShortDietaryPattern
          statusComments
          tip
        }
      }
      consultationSchedule {
        time
      }
      recordInput {
        age
        bornYear
        creatinineLevel
        gender
        height
        id
        kidneyStatus
        weight
      }
      dietaryPattern {
        id
      }
      status
    }
  }
`;

const FRAGMENT_NODE_CONSULTATION = gql`
  fragment nodeConsultation on ConsultationType {
    id
    consultationType
    status
    applyDueDate
    consultationSchedule {
      time
    }
    recordInput {
      age
      bornYear
      creatinineLevel
      gender
      height
      id
      kidneyStatus
      weight
    }
    dietaryPattern {
      id
    }
    consultationResult {
      consultationResultLines {
        category
        status
        relatedShortDietaryPattern
        statusComments
        tip
      }
    }
  }
`;

export const NODE_CONSULTATION = gql`
  query NodeConsultation {
    readyConsultation: allConsultation(
      status: "ready"
      limit: 1
      ordering: "consultation_schedule__time"
    ) {
      data {
        ...nodeConsultation
      }
    }
    finishConsultation: allConsultation(
      status: "finish"
      limit: 1
      ordering: "-consultation_schedule__time"
    ) {
      data {
        ...nodeConsultation
      }
    }
  }
  ${FRAGMENT_NODE_CONSULTATION}
`;

export const ALL_CONSULTATION = gql`
  query AllConsultation($status: String, $ordering: String, $limit: Int, $offset: Int) {
    allConsultation(ordering: $ordering, limit: $limit, offset: $offset, status: $status) {
      data {
        id
        consultationType
        status
        applyDueDate
        consultationSchedule {
          time
        }
        recordInput {
          age
          bornYear
          creatinineLevel
          gender
          height
          id
          kidneyStatus
          weight
        }
        dietaryPattern {
          id
        }
        consultationResult {
          consultationResultLines {
            category
            status
            relatedShortDietaryPattern
            statusComments
            tip
          }
        }
      }
      totalCount
    }
  }
`;

// Mutation
export const UPDATE_CONSULTATION_INFO = gql`
  mutation UpdateConsultationInfo(
    $consultationId: String
    $dietaryPatternId: String
    $dietaryPatternLines: [DietaryPatternLineType]
    $userType: String
    $consultationType: String
    $schedule: String
    $applyDueDate: String
    $recordInputId: String
    $scheduleId: String
  ) {
    updateConsultationInfo(
      consultationId: $consultationId
      dietaryPatternId: $dietaryPatternId
      dietaryPatternLines: $dietaryPatternLines
      userType: $userType
      consultationType: $consultationType
      schedule: $schedule
      applyDueDate: $applyDueDate
      recordInputId: $recordInputId
      scheduleId: $scheduleId
    ) @client
  }
`;

export const CREATE_DIETARY_PATTERN = gql`
  mutation CreateDietaryPattern(
    $difficulty: String!
    $drinking: String!
    $salty: String!
    $workout: String!
    $hasDiabetes: Boolean
    $hasGout: Boolean
    $hasHyperlipemia: Boolean
    $isPartial: Boolean
    $kaliumLevel: Float
    $pLevel: Float
    $smoke: Boolean!
    $uricAcidLevel: Float
    $dietaryPatternLines: [DietaryPatternLineInputType]
  ) {
    createDietaryPattern(
      input: {
        dietaryPattern: {
          difficulty: $difficulty
          drinking: $drinking
          salty: $salty
          workout: $workout
          hasDiabetes: $hasDiabetes
          hasGout: $hasGout
          hasHyperlipemia: $hasHyperlipemia
          isPartial: $isPartial
          kaliumLevel: $kaliumLevel
          pLevel: $pLevel
          smoke: $smoke
          uricAcidLevel: $uricAcidLevel
        }
        dietaryPatternLines: $dietaryPatternLines
      }
    ) {
      dietaryPattern {
        id
      }
      ok
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_CONSULTATION = gql`
  mutation UpdateConsultation(
    $id: ID
    $dietaryPatternId: ID
    $consultationType: String
    $consultationScheduleId: ID
    $recordInputId: ID
  ) {
    updateConsultation(
      id: $id
      input: {
        dietaryPatternId: $dietaryPatternId
        consultationType: $consultationType
        consultationScheduleId: $consultationScheduleId
        recordInputId: $recordInputId
      }
    ) {
      consultation {
        id
      }
    }
  }
`;

export const UPDATE_CONSULTATION_TYPE = gql`
  mutation UpdateConsultationType($consultationId: ID, $consultationType: String) {
    updateConsultation(id: $consultationId, input: { consultationType: $consultationType }) {
      consultation {
        id
      }
    }
  }
`;

export const UPDATE_CONSULTATION_RECORD_INPUT = gql`
  mutation UpdateConsultationRecordInput($consultationId: ID, $recordInputId: ID) {
    updateConsultation(id: $consultationId, input: { recordInputId: $recordInputId }) {
      consultation {
        id
      }
    }
  }
`;

export const UPDATE_CONSULTATION_DIETARY_PATTERN = gql`
  mutation UpdateConsultationDietaryPattern($consultationId: ID, $dietaryPatternId: ID) {
    updateConsultation(id: $consultationId, input: { dietaryPatternId: $dietaryPatternId }) {
      consultation {
        id
      }
    }
  }
`;

export const UPDATE_CONSULTATION_SCHEDULE = gql`
  mutation UpdateConsultationSchedule($consultationId: ID, $consultationScheduleId: ID) {
    updateConsultation(
      id: $consultationId
      input: { consultationScheduleId: $consultationScheduleId }
    ) {
      consultation {
        id
      }
    }
  }
`;
